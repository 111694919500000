import React, { useState, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import { FloatingLabel, Form, Button } from "react-bootstrap";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
// import 'react-summernote/lang/summernote-ru-RU';
import "bootstrap/js/src/modal.js";
import "bootstrap/js/src/dropdown.js";
import "bootstrap/js/src/tooltip.js";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";

import { LoadToast, isEmpty, updateToast, validation } from "../../../CommonHooks/commenfunctions";
import { Get_Cms } from "../../../Axios/admin.axios";

import { useHistory,useLocation } from 'react-router-dom'
const FormView = (props) => {
  const initial = {
    Name: "",
    Description:"",
    Path:"edit",
    Id:""
  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Content, setContent] = useState("");

  const { push } = useHistory();
  const location =useLocation()
  console.log("locationlocationlocation",formdata
  );

  useEffect(() => {
    setFormdata({...formdata,...{
      Id:location?.state?.data?._id,
      Name:location?.state?.data?.Type,
      Description:location?.state?.data?.Description,
    }})
    setContent(location?.state?.data?.Content)
  }, []);

  const onchange = (id,value) => {
    setErr({})
    setFormdata({ ...formdata, ...{ [id]: value } });
  };
  const handlesubmit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    if(isEmpty(Content)){
      updateToast(id, "fix");
      setErr({Content:"Content is required"});


    }
    setErr({});
    formdata.Content=Content
    console.log("formdataformdata0",formdata);

    let { status, msg,data } = await Get_Cms(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/cms");

       } 
  
       else{
        setErr(err);
        setloader(false)


       }

  };

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Edit CMS</h4>
          <div className="admin-forms mb-4 ">

            <div className="shadowbox p-4 br-10">
              <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  className="mb-3"
                >

                  <Form.Control
                  id="Name"
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Name}
                    onChange={(e)=>onchange('Name',e.target.value)}
                    disabled={true}

                  />
                </FloatingLabel>
                {err?.Name && (
                      <p className="text-left err_msg_txt">{err?.Name}</p>
                    )}
                
                {/* <div className="col-sm-6 mb-4">
                  <FloatingLabel controlId="floatingSelect" label="Status *">
                    <Form.Select
                      aria-label="Floating label select example"
                      value={status}
                      onChange={(e) => setStatus(e?.target?.value)}
                    >
                      <option value="">Select Status</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </Form.Select>
                  </FloatingLabel>
                </div> */}
              </div>
              <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                
                <FloatingLabel
                  controlId="floatingInput"
                  label="Describtion"
                  className="mb-3"
                >

                  <Form.Control
                    id="Description"
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Description}
                    onChange={(e)=>onchange('Description',e.target.value)}
                  />
                </FloatingLabel>
                {err?.Description && (
                      <p className="text-left err_msg_txt">{err?.Description}</p>
                    )}

              </div>

              {/* <div className="col-sm-6 mb-4">
                <h6>Image :</h6>
                <div className="upphoto my-3 ml-6">
                  <Button
                    variant="link"
                    className="nounder text-dark me-2 mb-2"
                  >
                    <div className={imageview && "d-none"}>
                      <span className="fa fa-photo" />
                      {console.log("imagesss", API_URL + path + "/" + image)}
                      <p>Upload image</p>
                    </div>
                    <img
                      src={imageview ? imageview : API_URL + path + "/" + image}
                    />

                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => {
                        console.log("e", e);
                        if (e?.target?.files[0]) {
                          setImageview(
                            URL.createObjectURL(e?.target?.files[0])
                          );
                          setImage(e?.target?.files[0]);
                        }
                      }}
                    />
                  </Button>
                </div>
              </div> */}


              <div
                className="px-4"
                // onDrop={() => console.log("")}
              >
                <h5 className="mb-3">Content *:</h5>

                <ReactSummernote
                  id="Content"
                  value={Content}

                  options={{
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      [
                        "style",
                        ["style", "bold", "italic", "underline", "clear"],
                      ],
                      [
                        "font",
                        [
                          "strikethrough",
                          "superscript",
                          "subscript",
                          "bold",
                          "underline",
                          "clear",
                        ],
                      ],
                      ["fontname", ["fontname"]],
                      ["fontsize", ["fontsize"]],
                      ["table", ["table"]],
                      ["color", ["color"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["insert", ["link", "picture", "video"]],
                      ["view", ["fullscreen", "codeview"]][
                        ("height", ["height"])
                      ],
                    ],
                  }}
                  onChange={(e)=>setContent(e)}

                />
              </div>
              {err?.Content && (
                      <p className="text-left err_msg_txt">{err?.Content}</p>
                    )}

              <div className="px-4 text-end mt-3 btn_view_oneline">
                <button className="btn btn-theme me-3" onClick={handlesubmit}>
                  Submit
                </button>
                <Button
                  variant="outline-dark"
                  className="f-12 fw-600 outlinebtn"
                  onClick={() => {
                    push("/cms");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormView;
